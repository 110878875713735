import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.initializeTinyMCE();
  }

  initializeTinyMCE() {

    tinymce.init({
      selector: 'textarea#default',
      promotion: false,
      setup: (editor) => {
        editor.on('init', (e) => {
          this.emitContentChangeEvent(editor.getContent());
        });

        editor.on('Change', (e) => {
          this.handleEditorChange(editor);
        });
      }
    });
  }

  handleEditorChange(editor) {
    const content = editor.getContent();
    // Update the preview and emit the content change event
    this.updatePreview(content);
    this.emitContentChangeEvent(content);
  }

  updatePreview(content) {
    const previewElement = document.getElementById('dynamicContent');
    if (previewElement) {
      previewElement.innerHTML = content;
    }
  }

  // Helper method to emit the content-changed event
  emitContentChangeEvent(content) {
    const contentChangeEvent = new CustomEvent("content-changed", {
      detail: { content: content },
      bubbles: true // Allows the event to bubble up the DOM
    });
    document.dispatchEvent(contentChangeEvent);
  }
}
