import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["banner", "collapsed", "mainContent", "messageForm", "messageTextarea"]

  connect() {
    this.handleCallNow = this.debounce(this.handleCallNow.bind(this), 300);
    document.addEventListener('triggerMessageForm', () => {
      this.expandBanner();
      this.toggleToMessageForm();
    });
    this.logUserInteraction = this.debounce(this.logUserInteraction.bind(this), 300);
    if (window.innerWidth <= 768) {
      this.collapseBanner();
    } else {
      this.expandBanner();
    }
  }

  debounce(func, delay) {
    let debounceTimer;
    return function(...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => { func.apply(this, args); }, delay);
    }
  }

  expandBanner() {
    this.bannerTarget.style.display = 'block';
    this.collapsedTarget.style.display = 'none';
    this.mainContentTarget.style.display = 'block';
    this.messageFormTarget.style.display = 'none';
    this.fadeIn(this.mainContentTarget, 1);
  }


  collapseBanner() {
    this.bannerTarget.style.display = 'none';
    this.collapsedTarget.style.display = 'block';
    this.messageFormTarget.style.display = 'none';
  }


  toggleToMessageForm() {
    const currentUrl = window.location.href;
    this.logUserInteraction('message_form', currentUrl)
    this.fadeOut(this.mainContentTarget, 250, () => {
      this.mainContentTarget.style.display = 'none';
      this.fadeIn(this.messageFormTarget, 250);
    });
  }

  toggleToMainContent() {
    this.fadeOut(this.messageFormTarget, 250, () => {
      this.messageFormTarget.style.display = 'none';
      this.fadeIn(this.mainContentTarget, 250);
    });
  }

  async sendMessage(event) {
    event.preventDefault();
    const message = this.messageTextareaTarget.value;

    const response = await fetch('/value_report/notify_message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({ message })
    });

    if (response.ok) {
      this.logUserInteraction('send_message', window.location.href);
      alert('Message sent successfully!');
      this.toggleToMainContent();
      this.messageTextareaTarget.value = '';
    } else {
      alert('Failed to send message.');
    }
  }

  async logUserInteraction(actionItem, destinationUrl) {
   
    const response = await fetch('/user_interactions/log_action', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({
        action_item: actionItem,
        destination_url: destinationUrl
      })
    });

    if (!response.ok) {
      console.error('Failed to log user interaction');
    }
  }

  handleCallNow(event) {
    event.preventDefault(); 
    const telUrl = event.currentTarget.href;
    this.logUserInteraction('call_now', telUrl).then(() => {
      window.location.href = telUrl;
    });
  }

  fadeOut(element, duration, callback) {
    element.style.transition = `opacity ${duration}ms`;
    element.style.opacity = 0;
    setTimeout(() => {
      callback();
    }, duration);
  }

  fadeIn(element, duration) {
    element.style.display = 'block';
    element.style.opacity = 0;
    element.style.transition = `opacity ${duration}ms`;
    setTimeout(() => {
      element.style.opacity = 1;
    }, 10);
  }
}
