import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    selectedMediaId: Number,
    content: String,
  }
  static targets = ["selectedMediaId", "emailContent", "saveButton", "form"]

  connect() {
    window.addEventListener("media-selected", this.handleMediaSelected.bind(this));
    window.addEventListener("content-changed", this.handleContentChanged.bind(this));
    
    this.selectedMediaIdValue = this.selectedMediaIdTarget.value;
    this.contentValue = this.emailContentTarget.value;

  }

  handleMediaSelected(event) {
    const { selectedId } = event.detail;
    this.selectedMediaIdValue = selectedId;
    this.checkReadyToSave();
  }

  handleContentChanged(event) {
    const { content } = event.detail;
    this.contentValue = content;
    this.checkReadyToSave();
  }

  checkReadyToSave() {
    if (this.selectedMediaIdValue && this.contentValue) {
      this.enableSaveButton();
    }
  }

  enableSaveButton() {
    this.saveButtonTarget.classList.remove("hidden");
  }

  submitForm(event) {
    event.preventDefault();
    console.log(this.selectedMediaIdValue);
    console.log(this.contentValue);
    console.log(this.selectedMediaIdValueTarget);
    console.log(this.emailContentTarget);
    this.selectedMediaIdTarget.value = this.selectedMediaIdValue;
    this.emailContentTarget.value = this.contentValue;
    this.formTarget.submit();
  }

  disconnect() {
    window.removeEventListener("media-selected", this.handleMediaSelected.bind(this));
    window.removeEventListener("content-changed", this.handleContentChanged.bind(this));
  }
}
