import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pageNavbar", "link"]
  observer = null

  connect() {
    this.updateNavbarPosition = this.updateNavbarPosition.bind(this);
    window.addEventListener('scroll', this.updateNavbarPosition);
    this.updateNavbarPosition();

    this.createObservers();
  }

  createObservers() {
    const headerHeight = document.querySelector('header').offsetHeight;

    const options = {
      rootMargin: `-${headerHeight}px 0px 0px 0px`,
      threshold: 0.5,
    };

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('id');
        const link = this.pageNavbarTarget.querySelector(`a[href="#${id}"]`);
        if (entry.isIntersecting) {
          this.activateLink(link);
        } else {
          this.deactivateLink(link);
        }
      });
    }, options);
  
    document.querySelectorAll('div[id]').forEach(div => {
      this.observer.observe(div);
    });
  }

  activateLink(link) {
    if (link) {
      link.classList.add('underline-offset', 'active', 'text-cyan'); 
      this.scrollIntoViewIfNeeded(link);
    }
  }
  
  deactivateLink(link) {
    if (link) {
      link.classList.remove('underline-offset', 'active', 'text-cyan');
    }
  }
  
  scrollIntoViewIfNeeded(element) {
    const scrollLeft = element.offsetLeft - this.pageNavbarTarget.offsetWidth / 2 + element.offsetWidth / 2;
    this.pageNavbarTarget.scroll({
      left: scrollLeft,
      behavior: 'smooth'
    });
  }

  updateNavbarPosition() {
    const header = document.querySelector('header');
    const banner = document.getElementById('custom-banner');
    const headerHeight = header.offsetHeight;
    const bannerHeight = banner ? banner.offsetHeight : 0;
    const pageNavbar = this.pageNavbarTarget;
  
    if (window.scrollY > headerHeight) {
      pageNavbar.classList.add('sticky', 'top-0', 'z-40');
      pageNavbar.style.top = `${headerHeight + bannerHeight}px`;
    } else {
      pageNavbar.classList.remove('sticky', 'z-40');
      pageNavbar.style.top = banner ? `${bannerHeight}px` : '0px';
    }
  }
  disconnect() {
    window.removeEventListener('scroll', this.updateNavbarPosition);
    if (this.observer) {
      document.querySelectorAll('section[id]').forEach(section => {
        this.observer.unobserve(section);
      });
      this.observer = null; 
    }
  }
  
}
