import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["optionId"]
  close() {
    this.element.remove();
    
    const header = document.querySelector('header');
    if (header) {
      header.style.top = '0px';
    }
  }

  connect() {
    const header = document.querySelector('header');
    if (header) {
      header.style.top = `${this.element.offsetHeight}px`;
    }
  }

  submitAnswer(event) {
    event.preventDefault();
    
    const button = event.target;
    const banner = this.element; 
    const form = this.element.querySelector('form');
    let formData = new FormData(form);

    const optionIdValue = button.dataset.optionId;

    if (optionIdValue === 'close') {
      formData.delete('option_id');
    } else {
      formData.set('option_id', optionIdValue);
      button.innerHTML = '✓';
      button.disabled = true;
      button.classList.add('bg-green', 'text-white');
      banner.classList.add('bg-green');
    }

    fetch(form.action, {
      method: 'POST',
      body: formData,
      headers: { 'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content },
      credentials: 'include'
    }).then(response => {
      if (response.ok) {

        setTimeout(() => {
          this.element.classList.add('animate-fade-out');
          setTimeout(() => this.close(), 500);
        }, 1000);
      } else {
        button.innerHTML = 'Try again';
        button.disabled = false;
        button.classList.remove('bg-green-500', 'text-white');
        banner.classList.remove('bg-green-500');
      }
    });
  }
}

