import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";
import * as Turbo from "@hotwired/turbo";
import * as ActiveStorage from "@rails/activestorage";
import.meta.globEager("../javascript/custom/*.js");

ActiveStorage.start();

Turbo.session.drive = false;
Turbo.start();

const application = Application.start();
const controllers = import.meta.globEager("../javascript/controllers/*.js");
registerControllers(application, controllers);
