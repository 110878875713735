import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "refi", "heloc", "sellAgent", "sellSelf", "refiLink", "helocLink", "sellAgentLink", "sellSelfLink",
        "loanOfficerButton", "loanOfficerForm", "uploadForm"
      ]

      connect() {
        super.connect();
        this.checkURLParamsAndFilter();
      }

      checkURLParamsAndFilter() {
        const urlParams = new URLSearchParams(window.location.search);
        const stay = urlParams.get('stay');
        const move = urlParams.get('move');
        
        if (stay === 'true') {
          this.showRelevantSections('stay');
        }
        if (move === 'true') {
          this.showRelevantSections('move');
        }
      }

      filterOptions(event) {
        console.log('clicked')
        const filter = event.currentTarget.dataset.filterValue;
        console.log('filter', filter)
        this.showRelevantSections(filter);
    }
    toggleLoanOfficerForm() {
        this.loanOfficerButtonTarget.classList.add("hidden");
    
        const confirmationMessage = this.element.querySelector('#confirmation_message');
        if (confirmationMessage) {
            confirmationMessage.classList.remove("hidden");
            setTimeout(() => {
                confirmationMessage.classList.add("visible");
            }, 100);
        }
    
        setTimeout(() => {
            this.loanOfficerFormTarget.classList.remove("hidden");
            this.loanOfficerFormTarget.classList.add("visible");
        }, 600);
    }
    
    toggleUploadForm() {
        this.uploadFormTarget.classList.toggle("hidden");
    
        if (!this.uploadFormTarget.classList.contains("hidden")) {
            setTimeout(() => {
                this.uploadFormTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 100);
        }
    }
    
    showRelevantSections(filter) {
    
        const refiSection = this.refiTarget;
        const helocSection = this.helocTarget;
        const sellAgentSection = this.sellAgentTarget;
        const sellSelfSection = this.sellSelfTarget;
    
        const isStayFilter = filter === 'stay';
        const isMoveFilter = filter === 'move';
    
        refiSection.classList.toggle("hidden", !isStayFilter);
        helocSection.classList.toggle("hidden", !isStayFilter);
        sellAgentSection.classList.toggle("hidden", !isMoveFilter);
        sellSelfSection.classList.toggle("hidden", !isMoveFilter);

    }
}
